import React from 'react';
import Accordian from './Accordion';

import './Faq.css';


function Faq() {
    return (
        <>
            <Accordian />    
        </>
    )
};

export default Faq